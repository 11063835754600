.title {
  color: white;
  span {
    font-weight: 300;
  }
}

.assembleia {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  p, h3 {
    color: black;
    margin: 0;
    text-align: center;
  }
  h3 {
    font-size: 1.5rem;
  }
  .switch {
    position: relative;
    display: block;
    width: 60px;
    height: 34px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
    
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }
  
  @at-root.on .slider {
    background-color: #48C864;
  }
  
  @at-root.on .slider {
    box-shadow: 0 0 1px #48C864;
  }
  
  @at-root.on .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
}
